import PropTypes from 'prop-types';
import HomeAbout from 'components/HomeAbout';
import Layout from 'components/Layout';
import HomeTags from 'components/HomeTags';
import dynamic from 'next/dynamic';
// import { tags } from 'data/tags';
// import { projects } from 'data/projects';
import { projects, tags } from '@garlink/bd-pkg-portfolio';

const HomeContact = dynamic(() => import('components/HomeContact'));
const HomePortfolio = dynamic(() => import('components/HomePortfolio'));

export default function Home({ tags, projects, route }) {
    return (
        <Layout route={route} section="home" seo={{ title: 'Home' }}>
            <HomeAbout />
            <HomeTags data={tags} />
            <HomeContact theme="dark" />
            <HomePortfolio data={projects} />
        </Layout>
    );
}

Home.propTypes = {
    tags: PropTypes.array,
    projects: PropTypes.array,
    route: PropTypes.string,
};

export async function getStaticProps(context) {
    return {
        props: { tags: tags, projects: projects },
    };
}
