import PropTypes from 'prop-types';
import Styles from './Footer.module.scss';

export default function Footer({ children }) {
    const year = new Date().getFullYear();

    return (
        <footer className={`footer has-background-primary ${Styles.footer}`}>
            <div className="content has-text-centered has-text-light">
                <p className="is-unselectable">&copy; {year} Bruno Domingos</p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    children: PropTypes.object,
};
