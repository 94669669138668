import Image from 'next/image';
import PropTypes from 'prop-types';

const myLoader = ({ src, width, quality }) => {
    return `https://res.cloudinary.com/garlink/image/upload${src}?w=${width}&q=${quality || 75}`;
    // return `https://res.cloudinary.com/garlink/image/upload/v1617995626${src}?w=${width}&q=${
    //     quality || 75
    // }`;
};

export default function MyImage({ alt, src, width, height, layout, loading }) {
    const cleanSrc = src.replace('/images', '');
    return (
        <Image
            loader={myLoader}
            src={cleanSrc}
            alt={alt || '...'}
            width={width || 500}
            height={height || 500}
            layout={layout || 'fixed'}
            loading={loading || 'eager'}
        />
    );
}

MyImage.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    layout: PropTypes.string,
    loading: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};
